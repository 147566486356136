import { ref, Ref } from 'vue'
import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notification', () => {
  const notifications: Ref<NotificationItem[]> = ref([])

  function notifySuccess(message: string, persistent: boolean = false) {
    notifications.value.push({
      id: window.crypto.randomUUID(),
      type: 'success',
      message,
      persistent,
      visible: true
    })
  }

  function notifyError(message: string, persistent: boolean = false) {
    notifications.value.push({
      id: window.crypto.randomUUID(),
      type: 'alert',
      message,
      persistent,
      visible: true
    })
  }

  function notify(message: string, persistent: boolean = false) {
    notifications.value.push({
      id: window.crypto.randomUUID(),
      type: 'message',
      message,
      persistent,
      visible: true
    })
  }

  function clearDisplayed() {
    notifications.value = notifications.value.filter((notification) => !notification.displayed)
  }

  function hide(id: string) {
    notifications.value.forEach((notification) => {
      if (notification.id == id) notification.visible = false
    })
  }

  return {
    notifications,
    notify,
    notifyError,
    notifySuccess,
    clearDisplayed,
    hide
  }
})
