import axios, { AxiosHeaders } from 'axios'
import { useAuthStore } from '@/stores/auth'

export const API_URL = import.meta.env.VITE_API_URL
export const ERROR_NETWORK = 'Something went wrong. Please try again.'
export const SERVER_ERROR = 'Something went wrong and has been reported.'
export const SERVER_VALIDATION_ERROR = "That didn't work. Please check and try again."
export const enum REQUEST_METHODS {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete'
}
export const enum STATUS_CODES {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER = 500
}
export enum FILTER_OPERANDS {
  EQUAL = '==',
  INCLUDES = 'ilike',
  LESS_THAN = '<',
  GREATER_THAN = '>',
  LESS_THAN_EQUAL = '<=',
  GREATER_THAN_EQUAL = '>=',
  IN = 'in',
  NOT_IN = 'not_in',
  NOT = 'not'
}

function buildAuthHeader(): AxiosHeaders {
  const authStore = useAuthStore()
  const token = authStore.getToken()
  if (token) {
    return new AxiosHeaders({ authorization: `Bearer ${token}` })
  } else {
    return new AxiosHeaders()
  }
}

export const doRequest = async (method: string, endpoint: string, data: any = {}): Promise<any> => {
  try {
    const result = await axios.request({
      method,
      url: `${API_URL}${endpoint}`,
      data: data,
      headers: buildAuthHeader()
    })

    return result.data
  } catch (error: any) {
    if (error.code == 'ERR_NETWORK') {
      return { error: ERROR_NETWORK }
    } else if (error.response?.status) {
      const base = {
        status_code: error.response.status,
        server_error: error.response.data?.error,
        ...(error.response.data || {})
      }

      if (error.response.status >= STATUS_CODES.INTERNAL_SERVER) {
        return { ...base, error: ERROR_NETWORK }
      } else if (error.response.status == STATUS_CODES.UNPROCESSABLE_ENTITY) {
        return { ...base, error: SERVER_VALIDATION_ERROR }
      } else if (error.response.status >= STATUS_CODES.BAD_REQUEST) {
        return { ...base, error: SERVER_ERROR }
      } else {
        return { ...base, error: 'Something went wrong' }
      }
    } else {
      return { error: ERROR_NETWORK }
    }
  }
}

export const pathWithPageAndFilters = (
  base: string,
  filters: ApiFilter[] = [],
  page: ApiPage = null,
  pageSize: number = 10,
  scopedBy?: string,
  scopedId?: string
): string => {
  let url = base
  const params: string[] = []

  if (scopedBy && scopedId) {
    url += `?scoped_by=${scopedBy}&scoped_id=${scopedId}`
  }

  if (page) {
    params.push(`page=${page}`)
  }

  params.push(`page_size=${pageSize}`)

  if (filters.length > 0) {
    params.push(buildFilters(filters))
  }

  if (params.length > 0) {
    url += (url.includes('?') ? '&' : '?') + params.join('&')
  }

  return url
}

const buildFilter = (filter: ApiFilter, index: number) => {
  let values = `filters[${index}][value]=${filter.value}`

  if (filter.op == FILTER_OPERANDS.IN || filter.op == FILTER_OPERANDS.NOT_IN) {
    values = (filter.value as string[]).map((value) => `filters[${index}][value][]=${value}`).join('&')
  }

  return `filters[${index}][field]=${filter.field}&filters[${index}][op]=${filter.op}&${values}`
}

export const buildFilters = (filters: ApiFilter[] = []) => {
  const filtersToStrings = filters.map((filter, index) => buildFilter(filter, index))
  return filtersToStrings.join('&')
}

export const doPost = async (endpoint: string, data: any = {}): Promise<any> => {
  return doRequest(REQUEST_METHODS.POST, endpoint, data)
}

export const doGet = async (endpoint: string): Promise<any> => {
  return doRequest(REQUEST_METHODS.GET, endpoint)
}

export const doPatch = async (endpoint: string, data: any = {}): Promise<any> => {
  return doRequest(REQUEST_METHODS.PATCH, endpoint, data)
}

export const doDelete = async (endpoint: string): Promise<any> => {
  return doRequest(REQUEST_METHODS.DELETE, endpoint)
}

export default {
  doGet,
  doPost,
  doPatch,
  doDelete,
  doRequest,
  pathWithPageAndFilters
}
